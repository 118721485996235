<template>
  <div
    id="mob_fixed_menu_container"
    class="mob_schedule-menu"
  >
    <div class="design-counter">
      .01
    </div>

    <div class="schedule-menu-title">
      {{ $t('forum schedule') }}
    </div>

    <div class="schedule-menu-subtitle">
      {{ $t('the broadcast schedule is indicated by moscow time (gmt +3)') }}
    </div>

    <div :class="menuClass">
      <div v-if="schedule" class="schedule-menu-block">
        <div
          v-for="day in schedule.days"
          :key="day.dayNumber"
          class="schedule-menu-block-day"
          :class="{'schedule-menu-block-day-active': selected === day.dayNumber }"
          @click="scrollToDay(day.dayNumber)"
        >
          <div class="schedule-menu-block-day-text">
            {{ $t('day') }} {{ day.dayNumber }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Классы css для фиксирования меню
const MENU_CLASSES = {
  fixed: 'mob_schedule-menu-fixed',
  none: ''
}

export default {
  props: {
    schedule: {
      type: Object,
      default: () => ({})
    }
  },

  data: () => ({
    selected: null,
    menuClass: MENU_CLASSES.none
  }),

  computed: {
    vDays () { return this.schedule?.days?.map(day => `day_${day.dayNumber}`) ?? [] }
  },

  mounted () {
    // TODO: выяснить причину глюка на айфоне при скроле
    // document.addEventListener('scroll', this.onScroll)
  },

  destroyed () {
    // document.removeEventListener('scroll', this.onScroll)
  },

  methods: {
    scrollToDay (day) {
      window.scrollTo({
        top: document.getElementById(`mob_day_${day}`).offsetTop - 400,
        behavior: 'smooth'
      })
    },

    onScroll () {
      // Помещаем в таймаут - чтобы дать отработать скролу
      setTimeout(() => {
        const menuTop = document
          .getElementById('mob_fixed_menu_container')
          .getBoundingClientRect()
          .top
        const speakersTop = document
          .getElementById('shcedule_speakers')
          .getBoundingClientRect()
          .top

        // Устанавливаем зазор в px
        const gap = 150

        if (menuTop < -gap) {
          // Если оступ до блока с меню меньше чем gap
          // то - фиксируе меню
          this.menuClass = MENU_CLASSES.fixed
        } else {
          this.menuClass = MENU_CLASSES.none
        }

        if (speakersTop < gap) {
          // Если доскролили до спикеров
          // то - убираем фиксацию
          this.menuClass = MENU_CLASSES.none
        }

        this.vDays.forEach((id, i) => {
          // если до расписания с определенным днем меньше чем 600px - указываем в меню определенный день
          if (document.getElementById(id).getBoundingClientRect().top < 600) {
            this.selected = i + 1
          }
        })
      }, 0)
    }
  }
}
</script>
