var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "schedule-menu", attrs: { id: "fixed_menu_container" } },
    [
      _c(
        "div",
        {
          class: _vm.menuClass,
          style: { left: _vm.fixeLeft + "px" },
          attrs: { id: "fixed_menu" }
        },
        [
          _c("div", { staticClass: "design-counter" }, [_vm._v(" .01 ")]),
          _c("div", { staticClass: "schedule-menu-title" }, [
            _vm._v(" " + _vm._s(_vm.$t("forum schedule")) + " ")
          ]),
          _c("div", { staticClass: "schedule-menu-subtitle" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t(
                    "the broadcast schedule is indicated by moscow time (gmt +3)"
                  )
                ) +
                " "
            )
          ]),
          _vm.schedule
            ? _c(
                "div",
                { staticClass: "schedule-menu-block" },
                _vm._l(_vm.schedule.days, function(day) {
                  return _c(
                    "div",
                    {
                      key: day.dayNumber,
                      staticClass: "schedule-menu-block-day",
                      class: {
                        "schedule-menu-block-day-active":
                          _vm.selected === day.dayNumber
                      },
                      on: {
                        click: function($event) {
                          return _vm.scrollToDay(day.dayNumber)
                        }
                      }
                    },
                    [
                      _c(
                        "v-icon",
                        { staticClass: "schedule-menu-block-day-arrow" },
                        [_vm._v(" mdi-arrow-right ")]
                      ),
                      _c(
                        "span",
                        { staticClass: "schedule-menu-block-day-text" },
                        [
                          _vm._v(
                            _vm._s(_vm.$t("day")) + " " + _vm._s(day.dayNumber)
                          )
                        ]
                      )
                    ],
                    1
                  )
                }),
                0
              )
            : _vm._e()
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }