var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mob_schedule-content", attrs: { id: "mob_schedule_list" } },
    [
      _vm.schedule
        ? _vm._l(_vm.schedule.days, function(day, index) {
            return _c(
              "div",
              {
                key: day.dayNumber,
                staticClass: "mob_schedule-content-list",
                attrs: { id: "mob_day_" + day.dayNumber }
              },
              [
                index !== 0
                  ? _c("div", { staticClass: "mob_schedule__divider" }, [
                      _c("span", { staticClass: "divider-start" }),
                      _c("p", { staticClass: "mob_schedule__day-title" }, [
                        _vm._v(" День 02 ")
                      ]),
                      _c("span", { staticClass: "divider-end" })
                    ])
                  : _vm._e(),
                _c("div", { staticClass: "mob_schedule-content-header" }, [
                  _c("hr", { staticClass: "mob_schedule-content-header-line" }),
                  _c(
                    "div",
                    { staticClass: "mob_schedule-content-header-day-text" },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("day")) +
                          " " +
                          _vm._s(day.dayNumber) +
                          " "
                      )
                    ]
                  )
                ]),
                _c(
                  "div",
                  { staticClass: "schedule-content-days" },
                  [
                    _c("ListSchedule", {
                      attrs: { items: day.items, "show-description": true }
                    })
                  ],
                  1
                )
              ]
            )
          })
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }