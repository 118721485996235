<template>
  <div class="schedule">
    <Menu :schedule="schedule" />
    <List :schedule="schedule" />
    <MobileMenu :schedule="schedule" />
    <MobileList :schedule="schedule" />
  </div>
</template>

<script>
import Menu from '@/modules/PageSchedule/components/Schedule/Menu'
import List from '@/modules/PageSchedule/components/Schedule/List'
import MobileMenu from '@/modules/PageSchedule/components/Schedule/MobileMenu'
import MobileList from '@/modules/PageSchedule/components/Schedule/MobileList'
import { mapState } from 'vuex'
import store from '@/modules/PageSchedule/store'

export default {
  store,

  components: {
    Menu,
    List,
    MobileMenu,
    MobileList
  },

  computed: {
    ...mapState(['schedule'])
  }
}
</script>
