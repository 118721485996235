<template>
  <div
    id="shcedule_speakers"
    class="speakers"
  >
    <div class="speakers-left">
      <div class="design-counter">
        .02
      </div>
      <div class="speakers-left-title">
        {{ $t('forum speakers') }}
      </div>
      <div class="speakers-left-subtitle">
        {{ $t('the broadcast schedule is indicated by moscow time (gmt +3)') }}
      </div>
    </div>
    <div class="speakers-right">
      <div class="speakers-right-list">
        <div
          v-for="(s,i) in speakers"
          :key="`${s.email}_${i}`"
          class="speakers-right-list-item"
        >
          <v-lazy
            :options="{
              threshold: .5
            }"
            transition="fade-transition"
          >
            <div class="speaker-item">
              <div class="speakers-right-list-item-ava">
                <router-link :to="`/profile-speaker/${s.slug}`">
                  <Avatar
                    :src="getLinkByFileName('speakers', 'large', s.photo)"
                    :alt="s.name"
                    tile
                  />
                </router-link>

                <div class="speakers-right-list-item-soc">
                  <a
                    v-if="s.instagram"
                    class="speakers-right-list-item-soc-item"
                    target="_blank"
                    :href="s.instagram"
                  >
                    <c-img
                      src="assets/img/svg/instagram.svg"
                      alt="live"
                      height="21"
                    />
                  </a>
                  <a
                    v-if="s.twitter"
                    class="speakers-right-list-item-soc-item"
                    target="_blank"
                    :href="s.twitter"
                  >
                    <c-img
                      src="assets/img/svg/twitter.svg"
                      alt="live"
                      height="21"
                    />
                  </a>
                  <a
                    v-if="s.facebook"
                    class="speakers-right-list-item-soc-item"
                    target="_blank"
                    :href="s.facebook"
                  >
                    <c-img
                      src="assets/img/svg/facebook.svg"
                      alt="live"
                      height="21"
                    />
                  </a>
                  <a
                    v-if="s.telegram"
                    class="speakers-right-list-item-soc-item"
                    target="_blank"
                    :href="s.telegram"
                  >
                    <c-img
                      src="assets/img/svg/telegram.svg"
                      alt="live"
                      height="21"
                    />
                  </a>
                  <a
                    v-if="s.site"
                    class="speakers-right-list-item-soc-item"
                    target="_blank"
                    :href="s.site"
                  >
                    <c-img
                      src="assets/img/svg/globe.svg"
                      alt="live"
                      height="21"
                    />
                  </a>
                  <a
                    v-if="s.email"
                    class="speakers-right-list-item-soc-item"
                    :href="`mailto:${s.email}`"
                  >
                    <c-img
                      src="assets/img/svg/mail.svg"
                      alt="live"
                      height="21"
                    />
                  </a>
                </div>
              </div>
              <div class="speakers-right-list-item-name">
                <router-link :to="`/profile-speaker/${s.slug}`">
                  {{ s.name }}
                </router-link>
              </div>
              <div class="speakers-right-list-item-postion">
                {{ s.position }}
              </div>
            </div>
          </v-lazy>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from '@/modules/PageSchedule/store'
import { mapState } from 'vuex'
import { getLinkByFileName } from '@/utils/mediaHelpers'
import Avatar from '@/components/Avatar'

export default {
  store,

  components: {
    Avatar
  },

  computed: {
    ...mapState(['speakers'])
  },

  methods: {
    getLinkByFileName
  }
}
</script>
