var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "schedule-content", attrs: { id: "schedule_list" } },
    [
      _vm.schedule
        ? _vm._l(_vm.schedule.days, function(day) {
            return _c(
              "div",
              {
                key: day.dayNumber,
                staticClass: "schedule-content-list",
                attrs: { id: "day_" + day.dayNumber }
              },
              [
                _c("div", { staticClass: "schedule-content-header" }, [
                  _c("div", { staticClass: "schedule-content-header-day" }, [
                    _c(
                      "span",
                      { staticClass: "schedule-content-header-day-text" },
                      [
                        _vm._v(
                          _vm._s(_vm.$t("day")) + " " + _vm._s(day.dayNumber)
                        )
                      ]
                    )
                  ]),
                  _c("div", { staticClass: "schedule-content-header-time" }, [
                    _vm._v(
                      " " +
                        _vm._s(day.startTime) +
                        " - " +
                        _vm._s(day.endTime) +
                        " "
                    )
                  ])
                ]),
                _c("ListSchedule", {
                  attrs: { items: day.items, "show-description": true }
                })
              ],
              1
            )
          })
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }