import Vuex from 'vuex'
import Vue from 'vue'
import * as contentAPI from '@/services/api/translatesAndContent'

const defaultState = () => ({
  schedule: null,
  speakers: []
})

// Мок данные для даты дня в рассписании
// const mockDate = (day) => {
//   const date = new Date()
//   date.setDate(date.getDate() + day - 1)
//   return date
// }

export default new Vuex.Store({
  strict: process.env.NODE_ENV === 'development',

  state: defaultState(),

  getters: {
    deserializeSchedule: (state, getters) => data => ({
      days: data.map((el, index) => getters.deserializeScheduleDay(el, index))
    }),

    deserializeScheduleDay: (state, getters) => (data, index) => {
      const items = data.map(el => getters.deserializeScheduleItem(el))
      return {
        dayNumber: index + 1,
        // Определяем начало и конец дня
        startTime: items[0].start,
        endTime: items[items.length - 1].end,
        items
      }
    },

    deserializeScheduleItem: (state, getters) => data => ({
      description: data.description,
      end: data.end,
      key: data.key,
      presentation: data.presentation,
      priority: data.priority,
      speakers: data.speakers?.map(el => getters.deserializeScheduleSpeaker(el)),
      start: data.start,
      title: data.title
    }),

    deserializeScheduleSpeaker: () => data => ({
      schedule_id: data.schedule_id,
      name: data.name,
      position: data.position,
      instagram: data.instagram,
      twitter: data.twitter,
      facebook: data.facebook,
      site: data.site,
      email: data.email,
      photo: data.photo,
      photo_in_schedule: data.photo_in_schedule,
      schedule_priority: data.schedule_priority
    })
  },

  actions: {
    async getContent ({ dispatch }) {
      await Promise.all([
        dispatch('getSpeakers'),
        dispatch('getSchedule')
      ])
    },

    async getSpeakers ({ commit }) {
      const { data } = await contentAPI.getSpeakers()
      commit('setSpeakers', data)
    },

    async getSchedule ({ commit, getters }) {
      const { data } = await contentAPI.getSchedules()
      commit('setSchedule', getters.deserializeSchedule(data))
    }
  },

  mutations: {
    setSpeakers (state, payload) {
      const speakers = Object.entries(payload).map(([id, obj]) => ({ ...obj, id }))
      Vue.set(state, 'speakers', speakers)
    },

    setSchedule (state, payload) {
      Vue.set(state, 'schedule', payload)
    }
  }
})
