<template>
  <div
    id="fixed_menu_container"
    class="schedule-menu"
  >
    <div
      id="fixed_menu"
      :class="menuClass"
      :style="{left: fixeLeft + 'px'}"
    >
      <div class="design-counter">
        .01
      </div>

      <div class="schedule-menu-title">
        {{ $t('forum schedule') }}
      </div>

      <div class="schedule-menu-subtitle">
        {{ $t('the broadcast schedule is indicated by moscow time (gmt +3)') }}
      </div>

      <div v-if="schedule" class="schedule-menu-block">
        <div
          v-for="day in schedule.days"
          :key="day.dayNumber"
          class="schedule-menu-block-day"
          :class="{'schedule-menu-block-day-active': selected === day.dayNumber }"
          @click="scrollToDay(day.dayNumber)"
        >
          <v-icon class="schedule-menu-block-day-arrow">
            mdi-arrow-right
          </v-icon>

          <span class="schedule-menu-block-day-text">{{ $t('day') }} {{ day.dayNumber }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Классы css для фиксирования меню
const MENU_CLASSES = {
  fixed: 'schedule-menu-fixed',
  toBottom: 'schedule-menu-to_bottom',
  none: ''
}

export default {
  props: {
    schedule: {
      type: Object,
      default: () => ({})
    }
  },

  data: () => ({
    selected: null,
    menuClass: MENU_CLASSES.none,
    fixeLeft: 0
  }),

  computed: {
    vDays () { return this.schedule?.days?.map(day => `day_${day.dayNumber}`) ?? [] }
  },

  mounted () {
    document.addEventListener('scroll', this.onScroll)
  },

  destroyed () {
    document.removeEventListener('scroll', this.onScroll)
  },

  methods: {
    scrollToDay (day) {
      window.scrollTo({
        top: document.getElementById(`day_${day}`).offsetTop - 100,
        behavior: 'smooth'
      })
    },

    onScroll () {
      // Помещаем в таймаут - чтобы дать отработать скролу
      setTimeout(() => {
        const menuTop = document
          .getElementById('fixed_menu_container')
          .getBoundingClientRect()
          .top
        const list = document
          .getElementById('schedule_list')
          .getBoundingClientRect()
        const menuHeight = document
          .getElementById('fixed_menu')
          .getBoundingClientRect()
          .height

        // Устанавливаем зазор в px
        const gap = 150

        if (list.bottom < menuHeight + gap) {
          // Если нижний отступ списка с расписанием
          // меньше чем высота блока с меню + зазор
          // устанавливаем блок с меню внизу списка с расписанием
          this.menuClass = MENU_CLASSES.toBottom
        } else if (menuTop < gap) {
          // Если отступ от контейнера с меню меньше чем зазор
          // фиксируем блок с меню
          this.fixeLeft = document
            .getElementById('fixed_menu_container')
            .getBoundingClientRect().left
          this.menuClass = MENU_CLASSES.fixed
        } else {
          this.menuClass = MENU_CLASSES.none
        }

        this.vDays.forEach((id, i) => {
          // если до расписания с определенным днем меньше чем 600px - указываем в меню определенный день
          if (document.getElementById(id).getBoundingClientRect().top < 600) {
            this.selected = i + 1
          }
        })
      }, 0)
    }
  }
}
</script>
