var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-schedule" },
    [
      _c(
        "div",
        { staticClass: "schedule-header" },
        [
          _vm.currentStaticEvent.name === _vm.THEME_FORUM_22
            ? _c("div", { staticClass: "schedule-header__hero" }, [
                _c("img", {
                  staticClass: "schedule-header__hero__img",
                  attrs: {
                    src: require("@/assets/img/theme-forum-22/schedule-hero.png")
                  }
                })
              ])
            : _c("v-container", { staticClass: "schedule-header__container" }, [
                _c("div", { staticClass: "schedule-header__bg-content" }, [
                  _c("div", { staticClass: "schedule-header__bg-text" }, [
                    _c("span", [
                      _vm._v(" Ocean "),
                      _c("br"),
                      _vm._v(" of possibilities ")
                    ])
                  ])
                ]),
                _c("div", { staticClass: "schedule-header-logo-container" }, [
                  _c("div", { staticClass: "schedule-header-logo" }, [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/img/svg/forum_logo.svg"),
                        alt: ""
                      }
                    })
                  ])
                ]),
                _c("div", { staticClass: "schedule-header-text-conteiner" }, [
                  _c("div", { staticClass: "schedule-header-text" }, [
                    _vm._v(" SCHEDULE ")
                  ])
                ])
              ])
        ],
        1
      ),
      _c("Schedule"),
      _c("Speakers")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }