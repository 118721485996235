<template>
  <div
    id="schedule_list"
    class="schedule-content"
  >
    <template v-if="schedule">
      <div
        v-for="day in schedule.days"
        :id="`day_${day.dayNumber}`"
        :key="day.dayNumber"
        class="schedule-content-list"
      >
        <div class="schedule-content-header">
          <div class="schedule-content-header-day">
            <span class="schedule-content-header-day-text">{{ $t('day') }} {{ day.dayNumber }}</span>
          </div>

          <div class="schedule-content-header-time">
            {{ day.startTime }} - {{ day.endTime }}
          </div>
        </div>

        <ListSchedule :items="day.items" :show-description="true" />
      </div>
    </template>
  </div>
</template>

<script>
import ListSchedule from '@/components/ListSchedule'

export default {
  components: {
    ListSchedule
  },

  props: {
    schedule: {
      type: Object,
      default: () => ({})
    }
  }
}
</script>
