var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "speakers", attrs: { id: "shcedule_speakers" } },
    [
      _c("div", { staticClass: "speakers-left" }, [
        _c("div", { staticClass: "design-counter" }, [_vm._v(" .02 ")]),
        _c("div", { staticClass: "speakers-left-title" }, [
          _vm._v(" " + _vm._s(_vm.$t("forum speakers")) + " ")
        ]),
        _c("div", { staticClass: "speakers-left-subtitle" }, [
          _vm._v(
            " " +
              _vm._s(
                _vm.$t(
                  "the broadcast schedule is indicated by moscow time (gmt +3)"
                )
              ) +
              " "
          )
        ])
      ]),
      _c("div", { staticClass: "speakers-right" }, [
        _c(
          "div",
          { staticClass: "speakers-right-list" },
          _vm._l(_vm.speakers, function(s, i) {
            return _c(
              "div",
              {
                key: s.email + "_" + i,
                staticClass: "speakers-right-list-item"
              },
              [
                _c(
                  "v-lazy",
                  {
                    attrs: {
                      options: {
                        threshold: 0.5
                      },
                      transition: "fade-transition"
                    }
                  },
                  [
                    _c("div", { staticClass: "speaker-item" }, [
                      _c(
                        "div",
                        { staticClass: "speakers-right-list-item-ava" },
                        [
                          _c(
                            "router-link",
                            { attrs: { to: "/profile-speaker/" + s.slug } },
                            [
                              _c("Avatar", {
                                attrs: {
                                  src: _vm.getLinkByFileName(
                                    "speakers",
                                    "large",
                                    s.photo
                                  ),
                                  alt: s.name,
                                  tile: ""
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "speakers-right-list-item-soc" },
                            [
                              s.instagram
                                ? _c(
                                    "a",
                                    {
                                      staticClass:
                                        "speakers-right-list-item-soc-item",
                                      attrs: {
                                        target: "_blank",
                                        href: s.instagram
                                      }
                                    },
                                    [
                                      _c("c-img", {
                                        attrs: {
                                          src: "assets/img/svg/instagram.svg",
                                          alt: "live",
                                          height: "21"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              s.twitter
                                ? _c(
                                    "a",
                                    {
                                      staticClass:
                                        "speakers-right-list-item-soc-item",
                                      attrs: {
                                        target: "_blank",
                                        href: s.twitter
                                      }
                                    },
                                    [
                                      _c("c-img", {
                                        attrs: {
                                          src: "assets/img/svg/twitter.svg",
                                          alt: "live",
                                          height: "21"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              s.facebook
                                ? _c(
                                    "a",
                                    {
                                      staticClass:
                                        "speakers-right-list-item-soc-item",
                                      attrs: {
                                        target: "_blank",
                                        href: s.facebook
                                      }
                                    },
                                    [
                                      _c("c-img", {
                                        attrs: {
                                          src: "assets/img/svg/facebook.svg",
                                          alt: "live",
                                          height: "21"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              s.telegram
                                ? _c(
                                    "a",
                                    {
                                      staticClass:
                                        "speakers-right-list-item-soc-item",
                                      attrs: {
                                        target: "_blank",
                                        href: s.telegram
                                      }
                                    },
                                    [
                                      _c("c-img", {
                                        attrs: {
                                          src: "assets/img/svg/telegram.svg",
                                          alt: "live",
                                          height: "21"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              s.site
                                ? _c(
                                    "a",
                                    {
                                      staticClass:
                                        "speakers-right-list-item-soc-item",
                                      attrs: { target: "_blank", href: s.site }
                                    },
                                    [
                                      _c("c-img", {
                                        attrs: {
                                          src: "assets/img/svg/globe.svg",
                                          alt: "live",
                                          height: "21"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              s.email
                                ? _c(
                                    "a",
                                    {
                                      staticClass:
                                        "speakers-right-list-item-soc-item",
                                      attrs: { href: "mailto:" + s.email }
                                    },
                                    [
                                      _c("c-img", {
                                        attrs: {
                                          src: "assets/img/svg/mail.svg",
                                          alt: "live",
                                          height: "21"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "speakers-right-list-item-name" },
                        [
                          _c(
                            "router-link",
                            { attrs: { to: "/profile-speaker/" + s.slug } },
                            [_vm._v(" " + _vm._s(s.name) + " ")]
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "speakers-right-list-item-postion" },
                        [_vm._v(" " + _vm._s(s.position) + " ")]
                      )
                    ])
                  ]
                )
              ],
              1
            )
          }),
          0
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }